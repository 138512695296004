// atlantis template dependencies
try {
   // core
   require('./config.js');
   window.Popper = require('@popperjs/core').default;
   window.$ = window.jQuery = require('jquery');
   window.jquerySlim = require('jquery/dist/jquery.slim.min.js');
   window.bootstrap = require('bootstrap');

   window.jQueryUi = require('jquery-ui');
   window.Swal = require('sweetalert2/dist/sweetalert2.min.js');

   require('select2/dist/js/select2.full.min.js')

   require('animate.css');

   require('./functions/_sweetAlerts');
   require('./functions/_scripts');
   
} catch (e) {}

$(document).on('keypress', 'form', function(e){
   if (e.keyCode === 13 || e.which === 13) {
       e.preventDefault();
       return false;
   }
});
