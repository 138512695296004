$(document).ready(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    getFeedback = (errors) => {
        $.each(errors, function(error, e){
            $("input[name='"+error+"']").addClass('is-invalid');
            $('.error-'+error).html(e);
        });
    }

    resetFeedback = () => {
        $(".form-control").removeClass('is-invalid');
        $(".form-note").html('');
    }

    function countVisitor()
    {
        $(document).on('click', '.count-visitor', function(e){
            var obj = $(this);
            var url = obj.data('url');

            $.ajax({
                type: 'GET',
                url: url,
                contentType: "application/json",
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // 
                }
            
            });

        });
    }

    $(document).on('click','.toggle-old-password',function(){
        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#old_pass_log_id");

        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    $(document).on('click','.toggle-password',function(){
        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#pass_log_id");

        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    $(document).on('click','.toggle-confirmation-password',function(){
        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#confirmation_pass_log_id");

        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    function generateSearch()
    {
        $(document).on('keyup', '#ajax_search', function( e ){
            var obj = $(this);
            e.preventDefault();
            var formSearch = $('#form-search');
            var inputSearch = obj.val();

            var action = formSearch.attr('action');
            var searchResult = $('#searchResult');

            var data = {
                "input_search" : inputSearch,
            };

            $.post(action, data, function(res){

                searchResult.html(res.data);

            }, "json");
            
        });
        
    }

    countVisitor();

    generateSearch();

});